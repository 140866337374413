<template>
	<div class="page">
		<p class="title">新老用户专享</p>
		<div class="courseList">
			<courseSelectCard @showDialogSuccess="showDialogSuccess" :courseData="item" v-for="item in courseList"
				:key="item.id"></courseSelectCard>
		</div>
		<courseSelectDialog @closeDialig="closeDialig" @selectSuccess="selectSuccess" :showDialog="showDialog">
		</courseSelectDialog>
	</div>
</template>

<script>
	import courseSelectCard from "./components/courseSelectCard.vue"
	import courseSelectDialog from "./components/courseSelectDialog.vue"
	import {
		selectCourseList,
		applyCourseList
	} from "@/api/index.js"
	export default {
		components: {
			courseSelectCard,
			courseSelectDialog
		},
		data() {
			return {
				courseList: [],
				showDialog: false,
				nowId: ""
			}
		},
		methods: {
			getInfo() {
				selectCourseList().then(res => {
					if (res.code === "0000") {
						// console.log(res.data)
						this.courseList = res.data.list
					}
				})
			},
			selectSuccess(dataTime) {
				let param = {
					classId: this.nowId,
					startDate: dataTime.date,
					// endDate: dataTime.date[1],
					classTime: dataTime.time
				}

				// console.log(param);
				applyCourseList(param).then(res => {
					if (res.code === "0000") {
            const div = document.createElement('div')
            /* 此处form就是后台返回接收到的数据 */
            div.innerHTML = res.data
            document.body.appendChild(div)
            document.forms[0].setAttribute('target', '_blank') // 新开窗口跳转
            document.forms[0].acceptCharset = 'utf-8';
            document.forms[0].submit()
						// this.$message.success("报名成功")
						this.showDialog = false
					} else {
						this.$message.error("报名失败")
						this.showDialog = false
					}
				})
			},
			closeDialig() {
				this.showDialog = false
			},
			showDialogSuccess(id) {
				this.nowId = id
				this.showDialog = true
			}
		},
		mounted() {
			this.getInfo()
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		text-align: center;
		font-size: 26px;
		font-weight: 800;
		margin-top: 50px;
	}

	.courseList {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
	}
</style>
