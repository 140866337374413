<template>
	<div>
		<div class="courseSelectCard">
			<div class="courseSelectCardConnent" @click="showDialogItem">
				<div class="connentTop" style="border-bottom: 1px dashed  #B3C0D1;padding-bottom: 10px;">
					<p
						style="font-size: 26px;font-weight: 900;line-height: 50px;width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
						{{ courseData.title }}
					</p>
					<p style="font-size: 18px;line-height: 50px"><i class="el-icon-bell"></i><span> 面向学员：幼儿~~中学 </span>
					</p>
					<p style="font-size: 18px;line-height: 50px"><i
							class="el-icon-bell"></i><span> 编程语言：{{ courseData.course | formatCourse }}</span></p>
					<p style="font-size: 18px;line-height: 50px"><i
							class="el-icon-bell"></i><span> 课堂类型：{{ courseData.classType | formatClassType }}</span></p>
					<p style="font-size: 18px;line-height: 50px;"><i class="el-icon-bell"></i><span> 上课时间：联系客服预约</span>
					</p>
					<p style="font-size: 18px;line-height: 50px;"><i class="el-icon-bell"></i>
						<span v-if="(courseData.id == 2 || courseData.id == 3 ||courseData.id == 4)"> 课堂时长：30分钟/课</span>
						<span v-if="!(courseData.id == 2 || courseData.id == 3 ||courseData.id == 4)"> 课堂时长：1小时/课</span>
					</p>
				</div>
				<div style="height: 40px;margin-top: 10px;">
					<div style="display: flex;justify-content: space-between;flex-wrap: nowrap;flex-direction: row;">
						<p style="line-height: 40px;height: 40px;font-size: 16px;">共{{ courseData.classNumber }}个课时</p>
						<p style="line-height: 40px;"><span
								style="font-size: 16px;color: #CCCCCC;text-decoration:line-through;">￥{{ courseData.classPrice * 6 }}</span><span
								style="font-size: 22px;line-height: 40px;color: #FF69B4;margin-left: 10px;">￥{{ courseData.classPrice }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import courseSelectDialog from "./courseSelectDialog.vue"

	export default {
		// components: {
		// 	courseSelectDialog
		// },
		filters: {
			formatGrade(grade) {
				switch (grade) {
					case 0:
						return "幼儿"
						break;
					case 1:
						return "小学"
						break;
					case 2:
						return "初中"
						break;
					case 3:
						return "高中"
						break;
					default:
						break;
				}
			},
			formatCourse(course) {
				switch (course) {
					case 0:
						return "Scratch"
						break;
					case 1:
						return "C/C++"
						break;
					case 2:
						return "Python"
						break;
					case 3:
						return "NOIP"
						break;
					default:
						break;
				}
			},
			formatClassType(classType) {
				switch (classType) {
					case 0:
						return "直播课"
						break;
					case 1:
						return "录播课"
						break;
					case 2:
						return "作品讲解"
						break;
					case 3:
						return "试卷解答"
						break;
					case 4:
						return "答疑解惑"
						break;
					case 9:
						return "试听课"
						break;
					default:
						break;
				}
			}
		},
		props: {
			courseData: {
				type: Object,
				default: () => ({})
			}
		},
		data() {
			return {
				// showDialog: false
			}
		},
		methods: {
			showDialogItem() {
				this.$emit("showDialogSuccess", this.courseData.id)
			}
		},
		mounted() {
			// console.log(this.courseData)
		}
	}
</script>

<style lang="scss" scoped>
	.courseSelectCard {
		width: 400px;
		height: 400px;
		margin-top: 50px;
		background-color: white;
		box-shadow: 5px 5px 5px #CCCCCC;
		transition: all .1s ease-in;
		border-top: 1px solid #FFFFFF;

		// padding: 20px 30px;
		.courseSelectCardConnent {
			// background-color: #409EFF;
			width: 360px;
			height: 360px;
			margin: 20px auto;
		}
	}

	.courseSelectCard:hover {
		border-top: 2px solid #FF69B4;
		transform: translateY(-5px);
		box-sizing: border-box;
		box-shadow: 10px 10px 5px #cccccc;
	}
</style>
