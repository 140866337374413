<template>
  <div>
    <el-dialog title="请选择开课时间" style="width: 1000px" :visible.sync="dialogFormVisible">
      <el-date-picker
          v-model="date"
          type="date"
          placeholder="选择日期">
      </el-date-picker>
      <br>
      <el-time-select style="margin-top: 30px;" v-model="time" :picker-options="{
			    start: '07:00',
			    step: '00:30',
			    end: '22:00'
			  }" placeholder="选择时间">
      </el-time-select>
      <div style="margin-top: 30px;color: red">
        注：具体上课时间联系客服预约
      </div>
      <div slot="footer" class="dialog-footer" style="margin-right: 50px;margin-top: -30px">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureSelect()">确 定</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    showDialog: {
      handler: function (n, o) {
        this.dialogFormVisible = this.showDialog
      },
      deep: true
    },
    dialogFormVisible: {
      handler: function (n, o) {
        if (this.dialogFormVisible) {

        } else {
          this.$emit("closeDialig")
        }
      },
      deep: true
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      date: "",
      time: ""
    }
  },
  methods: {
    sureSelect() {
      // console.log(this.date)
      if (this.date.length == 0) {
        this.$message.warning("请选择上课日期")
      } else if (this.time == "") {
        this.$message.warning("请选择上课时间")
      } else {
        let dataTime = {date: this.date, time: this.time}
        this.$emit("selectSuccess", dataTime)
      }
    }
  }
}
</script>

<style>
.classDialog {
  cursor: pointer; /*鼠标悬停变小手*/
}
.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
</style>
